<template>
  <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="90%">
  <div><span>1.请点击右上键 ...</span>
    <img src="@/assets/home/top.png" alt="" style="width: 100%">
</div>
  <div><span>2.在浏览器中打开</span>
<img src="@/assets/home/server.png" alt="" style="width: 100%"></div>
  
  <span slot="footer" class="dialog-footer">
    <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
    <el-button type="primary" @click="dialogVisible = false" size="small">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
    data() {
        return{
            dialogVisible: false
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
        }
    }
}
</script>

<style>

</style>